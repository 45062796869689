<template>
  <v-card class="teros-elevation">
    <v-dialog v-model="saveDialog" scrollable persistent :max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1 pr-8">
          <v-icon x-large class="mr-3">mdi-square-edit-outline</v-icon>
          {{ modalTitle }}
          <v-btn
            @click="doExitSaveDialog()"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <v-container>
            <v-row class="ml-n4 mr-0" v-if="saveDialog">
              <template v-for="(i, k) in modalFields">
                <input-v
                  class="pl-4"
                  :hideInform="i.hideInform"
                  :key="k"
                  v-model="selectedItem[i.key]"
                  :valid.sync="i.valid"
                  :block.sync="i.block"
                  :label="i"
                  :opts="opts"
                  :editable="i.editable != false"
                  :colSize="i.colSize"
                  :commentForField.sync="selectedItem[i.commentForField]"
                ></input-v>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="doExitSaveDialog()"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            dark
            depressed
            color="primary"
            class="px-5 ml-3"
            @click="doSave()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" scrollable persistent :max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon color="red" x-large class="mr-3">
            mdi-alert-circle-outline
          </v-icon>
          Exclusão
          <v-btn
            @click="deleteDialog = false"
            icon
            style="position: absolute; right: 5px; top: 5px"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <span v-html="deleteDialogMsg"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="pxF-5"
            dark
            depressed
            @click="deleteDialog = false"
          >
            Não
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              deleteDialog = false;
              doDelete();
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="px-5 text-uppercase v-title-cadastro">
      {{ pageTitle }}
    </v-card-title>
    <v-card-actions
      class="d-block px-5"
      :style="{ borderRadius: '5px' }"
    >
      <v-item-group v-model="currentStep" class="text-center mx-5" mandatory>
        <v-item v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.DADOS"
          >
            {{ stepName[stepEnum.DADOS] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :disabled="isCreate"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.DEDICACAO"
          >
            {{ stepName[stepEnum.DEDICACAO] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :disabled="isCreate"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.UNIDADE"
          >
            {{ stepName[stepEnum.UNIDADE] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :disabled="isCreate"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.ENCARGO"
          >
            {{ stepName[stepEnum.ENCARGO] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :disabled="isCreate"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.PESQUISADOR"
          >
            {{ stepName[stepEnum.PESQUISADOR] }}
          </v-btn>
        </v-item>
        <v-item v-if="ehLeiInformatica" v-slot="{ active }">
          <v-btn
            tile
            elevation="0"
            :class="{ active: active }"
            :disabled="isCreate"
            :width="itemButtonSize"
            @click="currentStep = stepEnum.TIPO_RH"
          >
            {{ stepName[stepEnum.TIPO_RH] }}
          </v-btn>
        </v-item>
      </v-item-group>
    </v-card-actions>
    <v-window v-model="currentStep" class="my-3">
      <!-- DADOS -->
      <v-window-item>
        <v-card-text v-if="colaborador" class="px-2 v-form-cadastro">
          <v-container>
            <v-row class="align-end">
              <template v-for="(i, k) in fields">
                <input-v
                  v-model="colaborador[i.key]"
                  class="px-2"
                  :key="k"
                  :label="i"
                  :opts="opts"
                  :colSize="i.colSize"
                  :valid.sync="i.valid"
                  :block.sync="i.block"
                  :hideInform="i.hideInform"
                  :editable="i.editable != false"
                ></input-v>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-5 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            class="pr-4"
            color="secondary"
            @click="doBack()"
          >
            <v-icon left>mdi-chevron-left</v-icon>
            {{ hasChanges ? "Cancelar" : "Voltar" }}
          </v-btn>
          <v-btn
            v-if="canDelete"
            dark
            depressed
            color="accent"
            class="px-4 ml-2"
            @click="deleteDialog = true"
          >
            Excluir
          </v-btn>
          <v-btn
            v-if="hasChanges"
            dark
            depressed
            class="px-4"
            color="primary"
            @click="doSave()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-window-item>
      <!-- DEDICAÇÕES -->
      <v-window-item eager>        
        <master-detail
          v-if="!isCreate"
          has-filter          
          resourceId="id"          
          ref="masterDetailDedicacoes"
          :cols="dedicacaoCols"
          :opts="opts"
          :actionBarButtons="actionBarButtons"
          :customResource="dedicacaoResource"
          :canEdit="false"
          :canDelete="false"
          :hasNewButton="false"        
          :contextOptions="contextOptions"                    
        ></master-detail>
      </v-window-item>
      <!-- UNIDADES -->
      <v-window-item eager>        
        <master-detail
          v-if="!isCreate"
          has-filter          
          resourceId="id"
          ref="masterDetailUnidade"
          :cols="unidadeCols"
          :opts="opts"
          :actionBarButtons="actionBarButtons"
          :customResource="unidadeResource"
          :canEdit="false"
          :canDelete="false"
          :hasNewButton="false"        
          :contextOptions="contextOptions"
        ></master-detail>
      </v-window-item>
      <!-- ENCARGOS -->
      <v-window-item eager>
        <master-detail
          v-if="!isCreate"
          has-filter          
          resourceId="id"
          ref="masterDetailEncargo"
          :cols="encargoCols"
          :opts="opts"
          :actionBarButtons="actionBarButtons"
          :customResource="encargoResource"
          :canEdit="false"
          :canDelete="false"
          :hasNewButton="false"        
          :contextOptions="contextOptions"
        ></master-detail>
      </v-window-item>
      <!-- PESQUISADOR -->
      <v-window-item eager>
        <master-detail
          v-if="!isCreate"
          has-filter          
          resourceId="id"
          ref="masterDetailPesquisador"
          :cols="pesquisadorCols"
          :opts="opts"
          :actionBarButtons="actionBarButtons"
          :customResource="pesquisadorResource"
          :canEdit="false"
          :canDelete="false"
          :hasNewButton="false"        
          :expandContextMenu="contextOptions"
          :contextOptions="contextOptions"     
        ></master-detail>
      </v-window-item>
      <!-- TIPO RH -->
      <v-window-item v-if="ehLeiInformatica" eager>
        <master-detail
          v-if="!isCreate"
          formTitle="Cadastro Tipo RH"
          :cols="tipoRHCols"
          :opts="opts"
          :resourceUrl="tipoRHResourceUrl"
          descriptionProperty="projetoId"
          :actionBarButtons="tipoRHExtraActionBarButtons"
          :canDelete="false"
          :canEdit="false"
          :hasNewButton="false"
          :serverPagination="false"
          :formWidth="700"
        ></master-detail>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),    
  },
  created: function () {
    if (this.isCreate) {
      this.colaborador = {};
      this.initOpts();
    } else {
      this.doLoad(true); // all = true para fazer todos o load de todas as abas
      this.initOpts();
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons: function () {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.selectedItem = {};
            this.saveDialog = true;
            this.ehCadastro = true;
          },
        },
      ];
    },
    canDelete: function () {
      return !this.isCreate && this.userHasAccessToAction('delete');
    },
    colaboradorId: function () {
      return this.$route.params.colaboradorId;
    },
    contextOptions: function () {
      return [
        {
          name: "Editar",
          limit: 1,
          icon: "",
          cb: (item) => {
            const competenciaDe = moment(item.competenciaDe, "MM/YYYY");
            const competenciaAte = moment(item.competenciaAte, "MM/YYYY");
            const admissao = moment(item.admissao, "DD/MM/YYYY");
            const desligamento = moment(item.desligamento, "DD/MM/YYYY");
            this.selectedItem = {
              ...cloneDeep(item),
              admissao: admissao.isValid()
                ? admissao.format("YYYY-MM-DD")
                : null,
              desligamento: desligamento.isValid()
                ? desligamento.format("YYYY-MM-DD")
                : null,
              competenciaDe: competenciaDe.isValid()
                ? competenciaDe.format("YYYY-MM")
                : null,
              competenciaAte: competenciaAte.isValid()
                ? competenciaAte.format("YYYY-MM")
                : null,
              inicioIndefinidoCheck: competenciaDe.isValid()
                ? []
                : ["inicio-indefinido"],
              fimIndefinidoCheck: competenciaAte.isValid()
                ? []
                : ["fim-indefinido"],
            };
            this.saveDialog = true;
            this.ehCadastro = false;
          },
        },
        {
          name: "Excluir",
          limit: -1,
          icon: "",
          cb: (item) => {
            this.selectedItem = cloneDeep(item);
            this.deleteDialog = true;
          },
        },
      ];
    },
    tipoRHExtraActionBarButtons: function () {
      return [
        {
          text: "Ver FOPAGs",
          icon: "mdi-file-eye",
          action: () => {
            this.$router.push({ name: "folha-pagamento-colaborador", params: { colaboradorId: this.colaboradorId }});
          },
        },
      ];
    },
    dedicacaoResource: function () {
      const f = this;
      const resource = this.apiResource(`/v1/rh/${this.clientId}/dedicacao/colaborador/${this.colaboradorId}`);
      return {
        ...resource,
        get() {
          return resource.get().then((result) => { return f.toRows(result)})  
        }
      }
    },
    unidadeResource: function () {
      const f = this;
      const resource = this.apiResource(`/v1/rh/${this.clientId}/unidade/colaborador/${this.colaboradorId}`);
      return {
        ...resource,
        get() {
          return resource.get().then((result) => { return f.toRows(result)})  
        }
      }
    },
    encargoResource: function () {
      const f = this;
      const resource = this.apiResource(`/v1/rh/${this.clientId}/grupoEncargo/colaborador/${this.colaboradorId}`);
      return {
        ...resource,
        get() {
          return resource.get().then((result) => { return f.toRows(result)})  
        }
      }
    },
    pesquisadorResource: function () {
      const f = this;
      const resource =  this.apiResource(`/v1/rh/${this.clientId}/pesquisador/colaborador/${this.colaboradorId}`);
      return {
        ...resource,
        get() {
          return resource.get().then((result) => { return f.toPesquisadorRows(result)})  
        }
      }
    },
    tipoRHResourceUrl: function () {
      return `/v1/rh/${this.clientId}/tiporh/${this.colaboradorId}`;
    },
    isCreate: function () {
      return this.$route.meta.mode === "create";
    },
    hasChanges: function () {
      const current = cloneDeep(this.colaborador);
      return !isEqual(current, this.colaboradorOriginal);
    },
    pageTitle: function () {
      return `${this.isCreate ? "Cadastro" : "Edição"} de ${
        this.colaboradorOriginal && this.colaboradorOriginal.nome
          ? this.colaboradorOriginal.nome
          : "Colaborador"
      }`;
    },
    modalTitle: function () {
      return `${
        this.ehCadastro ? "Cadastro" : "Edição"
      } de ${this.stepName[this.currentStep]}`;
    },
    dedicacaoCols: function () {
      return [
        {
          key: "dedicacao",
          name: "Dedicação",
          type: this.$fieldTypes.SELECT,
          rel: { to: "dedicacoes", key: "id", name: "nome" },
        },
        {
          key: "inicioIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "inicioIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "fimIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "fimIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "competenciaDe",
          name: "Competência de",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.inicioIndefinidoCheck) &&
            this.selectedItem.inicioIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          colSize: this.ehCadastro ? 12 : 6,
        },
        {
          key: "competenciaAte",
          name: "Competência até",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.fimIndefinidoCheck) &&
            this.selectedItem.fimIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
      ];
    },
    unidadeCols: function () {
      return [
        {
          key: "unidadeId",
          name: "Unidades",
          type: this.$fieldTypes.SELECT,
          rel: { to: "unidades", key: "id", name: "unidade" },
        },
        {
          key: "inicioIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "inicioIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "fimIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "fimIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "competenciaDe",
          name: "Competência de",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.inicioIndefinidoCheck) &&
            this.selectedItem.inicioIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          colSize: this.ehCadastro ? 12 : 6,
        },
        {
          key: "competenciaAte",
          name: "Competência até",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.fimIndefinidoCheck) &&
            this.selectedItem.fimIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
      ];
    },
    encargoCols: function () {
      return [
        {
          key: "grupoId",
          name: "Encargos",
          type: this.$fieldTypes.SELECT,
          rel: { to: "grupoEncargos", key: "id", name: "grupo" },
        },
        {
          key: "inicioIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "inicioIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "fimIndefinidoCheck",
          type: this.$fieldTypes.CHECKBOXES,
          rel: { to: "fimIndefinidoCheck", key: "id", name: "nome" },
          hideInTable: true,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
        {
          key: "competenciaDe",
          name: "Competência de",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.inicioIndefinidoCheck) &&
            this.selectedItem.inicioIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          colSize: this.ehCadastro ? 12 : 6,
        },
        {
          key: "competenciaAte",
          name: "Competência até",
          editable: !(
            this.selectedItem &&
            Array.isArray(this.selectedItem.fimIndefinidoCheck) &&
            this.selectedItem.fimIndefinidoCheck.length > 0
          ),
          type: this.$fieldTypes.MONTH,
          hideInform: this.ehCadastro,
          colSize: 6,
        },
      ];
    },
    pesquisadorCols: function () {
      return [
        {
          key: "admissao",
          name: "Admitido em",
          type: this.$fieldTypes.DATE,
          hideInTable: true,
          colSize: this.ehCadastro ? 12 : 6,
        },
        {
          key: "desligamento",
          name: "Demitido em",
          type: this.$fieldTypes.DATE,
          hideInform: this.ehCadastro,
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "admissao",
          name: "Admitido em",
          hideInform: true,
          colSize: this.ehCadastro ? 12 : 6,
        },
        {
          key: "desligamento",
          name: "Demitido em",
          hideInform: true,
          colSize: 6,
        },
      ];
    },
    tipoRHCols: function () {
      return [
        {
          key: "formacaoli_nome",
          name: "Formação",
          align: -1,
          editOnTable: false,
          colSize: 2,
        },
        {
          key: "escolaridadeli_nome",
          name: "Escolaridade",
          align: -1,
          editOnTable: false,
          colSize: 2,
        },
        {
          key: "titulo",
          name: "Projeto",
          align: -1,
          editOnTable: false,
          colSize: 3,
        },
        {
          key: "tiporh",
          name: "Tipo RH",          
          rel: { to: "tipo_rh", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
          colSize: 2,
          editOnTable: true,
        },
      ];
    },
    deleteDialogMsg: function () {
      const colaborador = cloneDeep(this.colaboradorOriginal || {});
      const selectedItem = cloneDeep(this.selectedItem || {});

      if (this.selectedItem && this.currentStep === this.stepEnum.DEDICACAO) {
        const found = this.opts.dedicacoes.find(
          ({ id }) => id === selectedItem.dedicacao
        );
        selectedItem.dedicacao = found ? found.nome : "";
      } else if (
        this.selectedItem &&
        this.currentStep === this.stepEnum.UNIDADE
      ) {
        const found = this.opts.unidades.find(
          ({ id }) => id === selectedItem.unidadeId
        );
        selectedItem.unidade = found ? found.nome : "";
      } else if (
        this.selectedItem &&
        this.currentStep === this.stepEnum.ENCARGO
      ) {
        const found = this.opts.grupoEncargos.find(
          ({ id }) => id === selectedItem.grupoId
        );
        selectedItem.encargo = found ? found.nome : "";
      } else if (
        this.selectedItem &&
        this.currentStep === this.stepEnum.PESQUISADOR
      ) {
        selectedItem.admissao = this.$options.filters.toDate(selectedItem.admissao);
        selectedItem.desligamento = this.$options.filters.toDate(selectedItem.desligamento);
      }

      // TODO melhorar isso para diminuir a repetição de código, pois as mensagens são muito parecidas
      const msg = {
        [this.stepEnum
          .DADOS]: `Deseja realmente excluir o colaborador: <b>${colaborador.matricula} — ${colaborador.nome}</b>?`,
        [this.stepEnum
          .DEDICACAO]: `Deseja realmente excluir a dedicação <b>${selectedItem.dedicacao}</b> de <b>${selectedItem.competenciaDe}</b> até <b>${selectedItem.competenciaAte}</b> do histórico de <b>${colaborador.nome}</b>?`,
        [this.stepEnum
          .UNIDADE]: `Deseja realmente excluir a unidade <b>${selectedItem.unidade}</b> de <b>${selectedItem.competenciaDe}</b> até <b>${selectedItem.competenciaAte}</b> do histórico de <b>${colaborador.nome}</b>?`,
        [this.stepEnum
          .ENCARGO]: `Deseja realmente excluir o grupo de encargo <b>${selectedItem.grupo}</b> de <b>${selectedItem.competenciaDe}</b> até <b>${selectedItem.competenciaAte}</b> do histórico de <b>${colaborador.nome}</b>?`,
        [this.stepEnum
          .PESQUISADOR]: `Deseja realmente excluir o período como pesquisador de <b>${selectedItem.admissao}</b> até <b>${selectedItem.desligamento}</b> do histórico de <b>${colaborador.nome}</b>?`,
      };

      return msg[this.currentStep];
    },
    client: function () {
      return this.getClient();
    },
    fields: function () {
      return [
        {
          key: "matricula",
          name: "Matrícula",
          type: this.$fieldTypes.TEXT,
          colSize: 2,
          rules: [{ rule: "required" }],
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
        {
          key: "cpf",
          name: "CPF",
          type: this.$fieldTypes.CPF,
          colSize: 2,
        },
        {
          key: "pesquisador",
          name: "Pesquisador",
          colSize: 2,
          editable: false,
          type: this.$fieldTypes.SELECT,
          rel: { to: "pesquisador", key: "id", name: "nome" },
        },
        {
          key: "admissao_em",
          name: "Data Admissão",
          colSize: 2,
          type: this.$fieldTypes.DATE,
        },
        {
          key: "demissao_em",
          name: "Data Desligamento",
          colSize: 2,
          type: this.$fieldTypes.DATE,
        },
        {
          key: "area_setor",
          name: "Área / Setor",
          colSize: 4,
          type: this.$fieldTypes.COMBOBOX,
          rel: { to: "setores" },
        },
        {
          key: "cargo",
          name: "Cargo",
          colSize: 4,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "escolaridade",
          name: "Escolaridade LB",
          colSize: 3,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "formacao",
          name: "Curso/Formação LB",
          colSize: 3,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "escolaridadeLI",
          name: "Escolaridade LI",
          colSize: 3,
          type: this.$fieldTypes.SELECT,
          hideInform: !this.ehLeiInformatica,
          rel: { to: "escolaridadesLI", key: "id", name: "escolaridade" },
        },
        {
          key: "formacaoLI",
          name: "Formação LI",
          colSize: 3,
          type: this.$fieldTypes.SELECT,
          hideInform: !this.ehLeiInformatica,
          rel: { to: "formacoesLI", key: "id", name: "formacao" },
        },
        {
          key: "email",
          name: "Email",
          colSize: 8,
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "email" }],
        },
        {
          key: "telefone",
          name: "Telefone",
          colSize: 4,
          type: this.$fieldTypes.TELEPHONE,
        },
      ];
    },
    modalFields: function () {
      switch (this.currentStep) {
        case this.stepEnum.DEDICACAO:
          return this.dedicacaoCols;
        case this.stepEnum.UNIDADE:
          return this.unidadeCols;
        case this.stepEnum.ENCARGO:
          return this.encargoCols;
        case this.stepEnum.PESQUISADOR:
          return this.pesquisadorCols;
        default:
          return [];
      }
    },
    resourceColaborador: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/colaboradores`);
    },
    resourceGrupoEncargos: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/grupoEncargos`
      );
    },
    resourceUnidades: function () {
      return this.apiResource(`/v1/clientes/${this.clientId}/unidades`);
    },
    resourceSetores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/setores`);
    },
    resourceEscolaridades: function () {
      return this.apiResource(`/v1/rh/escolaridade`);
    },
    resourceFormacoes: function () {
      return this.apiResource(`/v1/rh/formacao`);
    },
    ehLeiInformatica: function () {
      const clientServico = this.$store.getters.clientServico;
      return [2, 3].includes(clientServico);
    },
    stepEnum: function () {
      return {
        DADOS: 0,
        DEDICACAO: 1,
        UNIDADE: 2,
        ENCARGO: 3,
        PESQUISADOR: 4,
        TIPO_RH: 5,
      };
    },
    stepName: function () {
      return ({
        [this.stepEnum.DADOS]: 'Dados',
        [this.stepEnum.DEDICACAO]: 'Dedicação',
        [this.stepEnum.UNIDADE]: 'Unidade',
        [this.stepEnum.ENCARGO]: 'Encargo',
        [this.stepEnum.TIPO_RH]: 'Tipo RH',
        [this.stepEnum.PESQUISADOR]: 'Pesquisador',
      });
    },
    itemButtonSize: function () {
      const numButtons = Object.keys(this.stepEnum).length;
      return `${100 / numButtons}%`;
    },
  },
  data: function () {
    return {
      currentStep: 0,
      saveDialog: false,
      ehCadastro: false,
      deleteDialog: false,
      colaboradorOriginal: null,
      colaborador: null,
      historicoDedicacao: [],
      historicoUnidade: [],
      historicoEncargo: [],
      historicoPesquisador: [],
      selectedItem: {},
      opts: {
        clientes: [],
        setores: [],
        grupoEncargos: [],
        unidades: [],
        permissoes: [],
        pesquisador: [
          {
            id: 1,
            nome: "Sim",
          },
          {
            id: 0,
            nome: "Não",
          },
        ],
        dedicacoes: [
          {
            id: 0,
            nome: "Nenhuma",
          },
          {
            id: 1,
            nome: "Exclusiva",
          },
          {
            id: 2,
            nome: "Parcial",
          },
        ],
        tipo_rh: [
          {
            id: 0,
            nome: "Nenhum",
          },
          {
            id: 1,
            nome: "Direto",
          },
          {
            id: 2,
            nome: "Indireto",
          },
        ],
        formacoesLI: [
          {
            id: 0,
            formacao: "Vazio",
          },
        ],
        escolaridadesLI: [
          {
            id: 0,
            escolaridade: "Vazio",
          },
        ],
        inicioIndefinidoCheck: [
          { id: "inicio-indefinido", nome: "Inicio indefinido" },
        ],
        fimIndefinidoCheck: [{ id: "fim-indefinido", nome: "Fim indefinido" }],
      },
    };
  },
  methods: {
    doExitSaveDialog: function () {
      this.selectedItem = {};
      this.saveDialog = false;
      this.ehCadastro = false;
    },
    isValidForm: function () {
      const invalid = this.fields
        .filter((i) => !i.hideInform && i.valid)
        .map((l) => (l.valid() ? "VALIDO" : "INVÁLIDO"))
        .find((valid) => valid == "INVÁLIDO");
      return !invalid;
    },
    doLoad: function (all = false, atualizadoPorPesquisador = false) {
      if (all || atualizadoPorPesquisador || this.currentStep === this.stepEnum.DADOS) {
        this.resourceColaborador
          .get(this.colaboradorId)
          .then((d) => {
            this.colaborador = d.funcionario;
            this.colaboradorOriginal = cloneDeep(d.funcionario);
          });
      }
      
      if (all || atualizadoPorPesquisador || this.currentStep === this.stepEnum.DEDICACAO) {
        this.dedicacaoResource.get().then((res) => {
          this.historicoDedicacao = res;
        });
      }
      
      if (all || this.currentStep === this.stepEnum.UNIDADE) {
        this.unidadeResource.get().then((res) => {
          this.historicoUnidade = res;
        });
      }
      
      if (all || this.currentStep === this.stepEnum.ENCARGO) {
        this.encargoResource.get().then((res) => {
          this.historicoEncargo = res;
        });
      }
      
      if (all || this.currentStep === this.stepEnum.PESQUISADOR) {
        this.pesquisadorResource.get().then((res) => {
          this.historicoPesquisador = res;
        });
      }
    },
    doBack: function () {
      this.$router.push({ name: "listagem-colaboradores" });
    },
    doSave: function () {
      let request = null;

      if (this.currentStep === this.stepEnum.DADOS && this.isValidForm()) {
        request = this.resourceColaborador.save(
          this.colaborador,
          this.colaborador.id
        );
      } else {
        const selectedItem = cloneDeep(this.selectedItem);
        delete selectedItem.inicioIndefinidoCheck;
        delete selectedItem.fimIndefinidoCheck;

        this.saveDialog = false;
        if (this.currentStep === this.stepEnum.DEDICACAO) {
          this.dedicacaoResource.save(selectedItem, selectedItem.id).then(() => {                                    
            this.$refs.masterDetailDedicacoes.doLoad();
          });
        } else if (this.currentStep === this.stepEnum.UNIDADE) {
          this.unidadeResource.save(selectedItem, selectedItem.id).then(() => {
            this.$refs.masterDetailUnidade.doLoad();
          });
        } else if (this.currentStep === this.stepEnum.ENCARGO) {
          this.encargoResource.save(selectedItem, selectedItem.id).then(() => {
            this.$refs.masterDetailEncargo.doLoad();
          });
        } else if (this.currentStep === this.stepEnum.PESQUISADOR) {
          this.pesquisadorResource.save(selectedItem, selectedItem.id).then(() => {
            this.$refs.masterDetailPesquisador.doLoad();
          });
        }
      }

      if (request) {
        request.then((resp) => {
          if(!resp.error) {
            if (this.isCreate) {
              this.$router.push({ name: "edicao-colaborador", params: { colaboradorId: resp.id }});
            } else {
              this.$notify({
                group: "geral",
                duration: 5000,
                type: "success",
                title: "Alterações salvas",
                text: "As alterações foram salvas com sucesso",
              })
            }
          }
          
        });
      }
    },
    doDelete: function () {      
      if (this.currentStep === this.stepEnum.DADOS) {
        this.resourceColaborador.delete(this.colaborador.id).then(() => {
          this.doBack();
        });
      } else if (this.currentStep === this.stepEnum.DEDICACAO) {        
        this.dedicacaoResource.delete(this.selectedItem.id).then(() => {
          this.$refs.masterDetailDedicacoes.doLoad();
        });
      } else if (this.currentStep === this.stepEnum.UNIDADE) {
        this.unidadeResource.delete(this.selectedItem.id).then(() => {
          this.$refs.masterDetailUnidade.doLoad();
        })      
      } else if (this.currentStep === this.stepEnum.ENCARGO) {
        this.encargoResource.delete(this.selectedItem.id).then(() => {
          this.$refs.masterDetailEncargo.doLoad();
        });
      } else if (this.currentStep === this.stepEnum.PESQUISADOR) { 
        this.pesquisadorResource.delete(this.selectedItem.id).then(() => {
          this.$refs.masterDetailPesquisador.doLoad();
        });
      }    
    },
    toRows: function (array) {
      return !Array.isArray(array)
        ? []
        : array.map((item) => ({
            ...item,
            competenciaAte:
              item.competenciaAte == 0
                ? "Fim indefinido"
                : this.$options.filters.toMonth(item.competenciaAte),
            competenciaDe:
              item.competenciaDe == 0
                ? "Inicio indefinido"
                : this.$options.filters.toMonth(item.competenciaDe),
          }));
    },
    toPesquisadorRows: function (array) {
      return !Array.isArray(array)
        ? []
        : array.map((item) => ({
            ...item,
            desligamento:
              item.desligamento == null
                ? "Fim indefinido"
                : this.$options.filters.toDate(item.desligamento),
            admissao:
              item.admissao == null
                ? "Inicio indefinido"
                : this.$options.filters.toDate(item.admissao),
          }));
    },
    rowsToPrint: function (rows) {
      return function (resolve) {
        resolve(rows);
      };
    },
    actContext: function (v, e) {
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: this.contextOptions,
      });
    },
    initOpts: function () {
      this.resourceFormacoes.get().then((response) => {
        this.opts.formacoesLI = response.formacao;
      });

      this.resourceEscolaridades.get().then((response) => {
        this.opts.escolaridadesLI = response.escolaridade;
      });

      this.resourceSetores.get().then((response) => {
        this.opts.setores = response.setores.map((s) => s.area_setor);
      });

      this.resourceGrupoEncargos.get().then((response) => {
        this.opts.grupoEncargos = response;
      });

      this.resourceUnidades.get().then((response) => {
        this.opts.unidades = response;
      });
    },
  },
  watch: {
    clientId: function () {
      this.doBack();
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO tenho que aplicar isso também aos datepickers do formulário
.v-dialog .v-input--is-readonly {
  opacity: 0.75;
  pointer-events: none;
}

.v-dialog .v-menu + .v-input--is-readonly {
  opacity: 1;
  pointer-events: auto;
}

.v-card__actions .v-item-group {
  .v-btn + .v-btn {
    border-left: 3px solid #FFFFFF;
  }

  :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .v-btn {
    background-color: #BEDEFC !important;
    font-size: 18px;

    &.active {
      background-color: #81B4EA !important;
      font-weight: 500;
    }
  }
}
</style>
